export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [];

export const dictionary = {
		"/(dashboard)": [30,[4]],
		"/(public)/404": [68,[14]],
		"/(dashboard)/activity": [31,[4,5]],
		"/(dashboard)/activity/quickbooks": [32,[4,5]],
		"/(dashboard)/activity/scheduled": [33,[4,5]],
		"/(dashboard)/announcements": [34,[4]],
		"/(backoffice)/backoffice": [17,[2]],
		"/(backoffice)/backoffice/mining": [18,[2]],
		"/(backoffice)/backoffice/mining/assets": [19,[2]],
		"/(backoffice)/backoffice/mining/credits": [20,[2]],
		"/(backoffice)/backoffice/mining/workers2": [22,[2]],
		"/(backoffice)/backoffice/mining/workers": [21,[2]],
		"/(backoffice)/backoffice/newcustomer": [23,[2]],
		"/(backoffice)/backoffice/playground": [24,[2,3]],
		"/(backoffice)/backoffice/playground/mpctest": [25,[2,3]],
		"/(backoffice)/backoffice/reports": [26,[2]],
		"/(backoffice)/backoffice/rsa": [27,[2]],
		"/(backoffice)/backoffice/transactions": [28,[2]],
		"/(backoffice)/backoffice/users": [29,[2]],
		"/(public)/clear": [69,[14,15]],
		"/(dashboard)/deposit": [35,[4,6]],
		"/(dashboard)/deposit/coinbase": [36,[4,6]],
		"/(public)/farewell": [70,[14]],
		"/(dashboard)/helpdesk": [37,[4]],
		"/(dashboard)/invoices": [38,[4]],
		"/(public)/invoice": [71,[14]],
		"/(login)/login": [66,[12]],
		"/(public)/mpctest": [72,[14]],
		"/(dashboard)/noaccess": [39,[4]],
		"/(dashboard)/pay": [40,[4]],
		"/(login)/register": [67,[13]],
		"/(dashboard)/request": [41,[4]],
		"/(dashboard)/settings": [42,[4,7]],
		"/(dashboard)/settings/accounts": [43,[4,7]],
		"/(dashboard)/settings/billing": [44,[4,7]],
		"/(dashboard)/settings/customers": [45,[4,7]],
		"/(dashboard)/settings/gas": [46,[4,7,8]],
		"/(dashboard)/settings/gas/details": [47,[4,7,8]],
		"/(dashboard)/settings/gateway": [48,[4,7,9]],
		"/(dashboard)/settings/gateway/fees": [49,[4,7,9]],
		"/(dashboard)/settings/gateway/gatewaysettings": [50,[4,7,9]],
		"/(dashboard)/settings/notifications": [51,[4,7]],
		"/(dashboard)/settings/personal/appearance": [52,[4,7,10]],
		"/(dashboard)/settings/personal/user": [53,[4,7,10]],
		"/(dashboard)/settings/quickbooks": [54,[4,7]],
		"/(dashboard)/settings/recipients": [55,[4,7]],
		"/(dashboard)/settings/security": [56,[4,7,11]],
		"/(dashboard)/settings/security/access": [57,[4,7,11]],
		"/(dashboard)/settings/security/activity": [58,[4,7,11]],
		"/(dashboard)/settings/security/approval": [59,[4,7,11]],
		"/(dashboard)/settings/security/devices": [60,[4,7,11]],
		"/(dashboard)/settings/transferrules": [61,[4,7]],
		"/(dashboard)/settings/users": [62,[4,7]],
		"/(dashboard)/transfer": [63,[4]],
		"/(dashboard)/uitest": [64,[4]],
		"/v0/[slug]": [73,[16]],
		"/(dashboard)/withdraw": [65,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';